<template>
  <div class="wrapper">
    <h3>
      People are naughtier without clothing. <br /><span>
        Start undressing!
      </span>
    </h3>
    <div class="description">
      Unrobe.ai's advanced AI technology allows you to digitally remove clothes
      from any images uploaded, revealing the tantalizing skin beneath. Upload
      your favorite portraits, and watch the fabric melt away, exposing your
      subjects in stunning detail.
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 600;
  font-size: 34px;
  line-height: 44px;
  text-align: center;
  color: white;
  span {
    background: #29abe2;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  h3 {
    padding: 0 30px;
  }
}

.description {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #dfdfdf;
  padding: 0px 10px;
  width: 90%;
  margin: 0 auto 80px;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  padding: 0 20px;
  &-top {
    @include flex-row-items-center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  &-bottom {
    @include flex-row-items-center;
    text-align: center;
    justify-content: center;
    width: calc(max-content + 60px);
    margin: 0 auto;
    gap: 40px;
    border-top: 1px solid #5c5d5f;
    padding: 40px 0 0 0;
  }
}

.item {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  max-width: 420px;
  img {
    margin: 0 auto;
  }
}

@media (max-width: 900px) {
  .wrapper {
    h3 {
      font-size: 36px;
    }
  }
}

@media (max-width: 600px) {
  .wrapper {
    h3 {
      font-size: 32px;
    }
  }
  .footer {
    flex-direction: column-reverse;
    &-top {
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-evenly;
      gap: 40px;
    }

    &-bottom {
      width: 100%;
      border: none;
      padding-top: 0;
    }
  }

  .description {
    margin-bottom: 40px;
  }

  .item {
    width: 40%;
    font-size: 18px;
  }
}
</style>
