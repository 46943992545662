<template>
  <div class="wrapper">
    <div class="background">
      <div class="gradient-ellipse gradient-ellipse--left"></div>
      <div class="gradient-ellipse gradient-ellipse--right"></div>
    </div>
    <div class="header">
      How to use <span>AI Clothes Remover</span> & get satisfied
    </div>
    <div class="content">
      <div class="video">
        <video autoplay loop muted playsinline>
          <source src="/gifs/unrobe-animation-mp9.webm" type="video/webm" />
          <track kind="captions" srclang="en" label="english_captions" />
        </video>
      </div>
      <div class="body">
        <div class="block">
          <div class="step">Step 1</div>
          <div class="title">Choose who you want to undress</div>
          <div class="description">
            Upload your favorite photos and use our simple and intuitive
            interface of the most advanced AI technology to strip away their
            fabric and reveal the tantalizing skin beneath.
          </div>
        </div>
        <div class="block">
          <div class="step">Step 2</div>
          <div class="title">Generate images to find your muse</div>
          <div class="description">
            Exclude unwanted elements with “Negative Prompt”. For example. type
            “no water” to keep your creation dry and steamy.
          </div>
        </div>
        <div class="block">
          <div class="step">Step 3</div>
          <div class="title">Choose Your Brushstrokes</div>
          <div class="description">
            Opt for “Realistic” for realistic portrayals or “Anime” for anime
            characters and a hentai twist.
          </div>
        </div>
        <div class="block">
          <div class="step">Step 4</div>
          <div class="title">Witness Your Creation Come Alive :</div>
          <div class="description">
            Our AI weaves your desires into a visual masterpiece, tailored to
            your every specification.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const $router = useRouter()
</script>

<style lang="scss" scoped>
.wrapper {
  margin-bottom: 90px;
}

.background {
  position: absolute;
  left: 0;
  width: 100vw;
  overflow: hidden;
  height: 800px;
}
.gradient-ellipse {
  position: absolute;
  background: linear-gradient(
    84.9deg,
    rgba(41, 171, 226, 0.24) 0%,
    rgba(105, 183, 149, 0.24) 50.5%,
    rgba(199, 217, 57, 0.24) 100%
  );
  filter: blur(100px);
  border-radius: 50%;
  background-origin: border-box;
  background-clip: padding-box, border-box;
  border: double 1px transparent;
  z-index: 0;
  &--left {
    width: 500px;
    height: 500px;
    top: 0;
    left: 0;
    transform: translate(-50%, 20%);
  }

  &--right {
    width: 450px;
    height: 450px;
    top: 0;
    right: 0;
    transform: translate(10%, 40%);
  }
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
}

.video {
  width: 50%;
  height: 100%;
  z-index: 4;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  video {
    max-width: 520px;
    width: 90%;
  }
}

.header {
  z-index: 5;
  text-align: center;
  width: 100%;
  color: white;
  text-align: center;
  font-weight: 700;
  font-size: 32px;
  line-height: 43px;
  margin-bottom: 40px;
  span {
    background: #29abe2;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.body {
  display: flex;
  flex-direction: column;
  gap: 50px;
  max-height: 390px;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 0 0 20px 40px;
  width: 50%;
  flex: 1;
  z-index: 5;
  border-left: 1px solid #69b795;
  border-left-style: dashed;
  margin-top: 20px;
}

.block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  color: white;
}

.step {
  border-radius: 24px;
  padding: 6px 18px;
  max-width: 50px;
  // background: rgba($color: #ffffff, $alpha: 0.06);
  background-image: linear-gradient(#040b13, #040b13),
    linear-gradient(84.9deg, #c7d939 0%, #69b795 50.5%, #29abe2 100%);
  background-color: #040b13;
  background-origin: border-box;
  background-clip: padding-box, border-box;
  border: double 1px transparent;
}

.title {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

.description {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #afafaf;
}

@media (max-width: 900px) {
  .video {
    video {
      max-width: 420px;
      width: 90%;
    }
  }
}

@media (max-width: 800px) {
  .content {
    flex-direction: column-reverse;
    max-width: 100%;
  }
  .body {
    flex-direction: row;
    width: 320px;
    max-height: none;
    gap: 220px;
    margin: 30px auto;
    padding: 0 20%;
  }
  .video {
    position: static;
    width: 100%;
    height: auto;
    video {
      margin: 0 auto;
      width: 100%;
    }
  }

  .block {
    min-width: 320px;
    justify-content: flex-start;
    align-items: center;
  }
  .title {
    text-align: center;
  }
  .description {
    text-align: center;
  }
}

@media (max-width: 600px) {
  .wrapper {
    margin-bottom: 30px;
  }
  .body {
    padding: 0 13%;
  }
}

@media (max-width: 450px) {
  .body {
    padding: 0 7%;
    width: calc(320px - 7%);
  }
  .block {
    min-width: calc(320px - 7%);
  }
}
</style>
